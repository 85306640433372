@charset "utf-8";
@import './include/var';


.gallery{
	margin-top: 30px;
	&-item{
		border: 1px solid #d9d9d9;
		border-top: 2px solid #000;
		padding: 10px;
		padding-right: 0;
		overflow: hidden;
		margin-bottom: 30px;
	}
	&-thumb{
		float: left;
		width: 290px;height: 202px;
		background-color: #f8f8f8;
		a{
			display: block;width: 100%;height: 100%;
			overflow: hidden;position: relative;
			&:hover{
				span,
				&:after{opacity: 1;visibility: visible;}
			}
			&:after{
				content: '';
			    position: absolute;
			    top: 0;
			    left: 0;
			    width: 100%;
			    height: 100%;
			    background-color: rgba(0, 0, 0, 0.2);
			    opacity: 0;visibility: hidden;
			    transition:all 0.25s;
			}
			span{
				transition:all 0.25s;
				    opacity: 0;
				    visibility: hidden;
				    position: absolute;
				    top: 50%;
				    left: 50%;
				    transform: translate(-50%, -50%);
				    text-align: center;
				    font-size: 30px;
				    color: #fff;
			}
			img{
				position: absolute;
				left: 50%;top: 50%;transform: translate(-50%,-50%);
			}
		}
	}
	&-caption{
		float: left;
		position: relative;
		padding-left: 20px;
		padding-top: 10px;
		height: 202px;
		width: calc(100% - 290px);
		.tit{
			height: 42px;
			border-bottom: 1px dashed #ddd;
			.cate{
				display: inline-block;
				color: #fff;
				background: #1e5caf url("#{$image}/board/icon-gallry-cate.png") no-repeat 8px 50%;
				font-size: 14px;padding-left: 28px;width: 90px;height: 30px;line-height: 30px;border-radius: 3px;
			}
			a{
				color: #1e5caf;font-size: 20px;
			}
		}
		.detail{
			height: 130px;border-bottom: 1px solid #ddd;
			padding-top: 20px;
			dl{position: relative;padding-left: 40px;
				dt{
					position: absolute;left: 0;top: 0;
					color: #1e5caf;font-size: 16px;
				}
				dd{
					font-size: 14px;color: #666;
				}
				+dl{
					margin-top: 10px;
				}
			}
		}
		.info{
			position: relative;height: 31px;line-height: 31px;font-size: 12px;color: #666666;
			.left{
				position: absolute;left: 0;
			}
			.right{
				position: absolute;right: 10px;
				border-left: 1px solid #ddd;
				padding-left: 10px;
			}
		}
	}
}
.info-item{
	display: inline-block;
	background-repeat: no-repeat;
	background-position: 0 50%;
	padding-left: 20px;
	position: relative;
	vertical-align: top;
	+.info-item{
		margin-left: 30px;
		&:after{
			content:"";display: inline-block;
			position: absolute;left: -15px;top: 10px;
			width: 1px;height: 12px;background-color: #747474;
		}
	}
}
.info-down{background-image: url("#{$image}/board/icon-download.png");}
.info-copy{background-image: url("#{$image}/board/icon-copy.png");}
.info-like{background-image: url("#{$image}/board/icon-like.png");}

.atlas{
	&-header{
		overflow: hidden;
		border: 1px solid #d9d9d9;border-top: 2px solid #000;
		padding: 0 24px;
		.info-item + .info-item:after{
			top: 3px;
		}
		.left{
			height: 47px;float: left;
			padding-top: 17px;
		}
		.like-push{
			float: right;
			padding-top: 8px;
			span{
				display: inline-block;
				font-size: 14px;color: #666666;line-height: 30px;vertical-align: top;
			}
			.btn-like-push{
				display: inline-block;width: 100px;height: 30px;vertical-align: top;
				background: url("#{$image}/board/btn-like.png") no-repeat 0 0;font-size: 0;
				margin-left: 5px;
			}
		}
	}
	&-content{
		border: 1px solid #555555;border-top: 0;
	}
	&-tab{
		display: block;
		&-menu{
			overflow: hidden;margin: 35px 0 40px;border: 1px solid #ddd;
			a{
				display: block;float: left;width: 20%;
				background-color: #f8fafc;text-align: center;
				color: #333333;font-size: 16px;height: 48px;line-height: 48px;
				+a{
					border-left: 1px solid #dddddd;
				}
				&.active{
					background-color: #2f71c9;color: #fff;
				}
			}
		}
		&-content{
			padding-left: 20px;padding-right: 20px;display: none;
			.row{
				border: 1px solid #dddddd;overflow: hidden;
				.col{float: left;width: 33.333%;
					text-align: center;
					height: 178px;padding-top: 18px;
					+.col{
						border-left: 1px solid #dddddd;
					}
					.tit{
						text-align: center;padding-bottom: 20px;
						p{
							height: 60px;
						}
						span{
							color: #2f71c9;font-size: 16px;
						}
					}
					.txt{
						color: #999999;font-size: 12px;
						display: inline-block;
						text-align: left;
					}
				}
			}
		}
	}
}
.copy-content{
	padding: 10px 0;
	border-top: 1px solid #ddd;
	overflow: hidden;
	position: relative;
	.tit,
	.frm{
		float: left;
	}
	.tit{
		background: url("#{$image}/board/icon-angle.png") no-repeat 0 0;font-size: 14px;color: #666666;
		padding-left: 15px;width: 145px;
	}
	.frm{
		width: 700px;
		input{
			border: 1px solid #dddddd;height: 34px;
			padding: 9px;width: 660px;
			&.v2{
				height: 78px;
			}
		}
	}
	.btn-atlas-down{
		width: 230px;height: 34px;line-height:34px;text-align: left;background:#768391 url("#{$image}/board/icon-alts-down.png") right 10px center no-repeat;
		border-color: #768391;padding-left: 17px;
	}
	.btn-frm-copy{
		position: absolute;right: 12px;top: 10px;width: 60px;height: 34px;background-color: #768391;border-radius: 3px;border-color: #768391;line-height:34px;
	}
	&.end{
		border-bottom: 1px solid #ddd;
	}
}

.angle-list{
	li{
		background: url("#{$image}/board/icon-angle.png") no-repeat 0 3px;
		padding-left: 16px;line-height: 1.48;font-size: 14px;color: #666666;
		.small{font-size: 12px;}
		+li{
			margin-top: 12px;
		}
		span{
			display: inline-block;padding-left: 40px;
		}
	}
}

.bo-foot{
	text-align: right;padding-top: 30px;
}
.btn-golist{
	background:#1e5caf url("#{$image}/board/icon-list.png") no-repeat 35px 50%;
	padding-left: 55px;text-align: left;border-radius: 3px;
	color: #fff;width: 120px;height: 40px;line-height: 40px;
}