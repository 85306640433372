$image : '../images';

//default
$root__fontSize: 14px;
$root__radius: 4px;

$root_color: #171717;

//media
$mediaMobile : 1000px;
$mediaTablet : 1200px;

//color
$color1 : #2a2a2a;

@mixin listRest ($ul:'ul') {
  #{$ul},li
  {
    margin:0;
    padding:0;
    list-style:none;
  }
}

@mixin clearBoth($selector) {
  #{$selector}::after{content: '';display: block;clear: both;}
}

@mixin cutTextRot($rows, $height){
  display:-webkit-box;
  -webkit-box-orient: vertical;height:$height;
  -webkit-line-clamp: $rows;text-overflow:ellipsis;overflow: hidden;
}