@charset "utf-8";
@import './include/var';



.modal-download-notice{
	padding-top: 20px;
	p.bold{
		color: #f95b0d;
	}
	p{
		line-height: 1.5;
		font-size: 14px;
	}
}


.sourcecode{
  border:1px solid #cecece;height:300px;background: #eeeeee;
  text-align: center;
  &::after{content: '소스코드';line-height: 300px;}
}


.icon-preview{
  display: inline-block;
  width: 20px;height: 20px;
  border-radius: 50%;
  vertical-align: middle;
  background: #fff;
  position: relative;
  margin-left: 5px;margin-top:-4px;
  &::after{content: '';
    width: 0px;height: 0px;
    position: absolute;
    top: 5px;left: 8px;
    $w : 5px;
    border-top:$w solid transparent;
    border-bottom:$w solid transparent;
    border-right: $w solid none;
    border-left: $w solid  #2a78e1;
  }
}