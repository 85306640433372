@charset "utf-8";
@import './include/var';


.mt-0{margin-top: 0 !important;}

/* =======================
  게시판 (질문과 답변)
======================= */
//paging
.board-paging {
  margin: 0 auto;
  text-align: center;
  &::after {display: block;content: '';clear: both;}
  div {
    display: inline-block;
    &::after {display: block;content: '';clear: both;}
  }
  a, strong {
    float: left;
    display: block;
    width:40px;
    height: 40px;line-height: 40px;
    border: 1px solid #dedede;
    border-left: 0;
    background: #fff;
    vertical-align: middle;
    box-sizing: border-box;
    text-align: center;
    &:first-child {border-left: 1px solid #dedede;}
  }
  strong {background: #2465c8;border-left: 1px solid #dedede;color: #fff;}
  .paging-first {background: no-repeat center url('#{$image}/board/paging-first.png');}
  .paging-last {background: no-repeat center url('#{$image}/board/paging-last.png');}
  .paging-prev {
    background: no-repeat center url('#{$image}/board/paging-prev.png');
    margin-right: 4px;}
  .paging-next {
    background: no-repeat center url('#{$image}/board/paging-next.png');
    margin-left: 3px;
  }
}

//btn
.board-btns{
  text-align: right;
  margin-top: 30px;
  &> * {
    min-width: 120px;
    height:46px; line-height: 46px;
    margin-left: 5px;
  }
}

//리스트
.board {
  .board-paging{margin-top: 70px;}
  &__info {
    margin-bottom: 40px;
    padding: 25px 0 25px 220px;
    border: 1px solid #d8dadd;
    p {
      position: relative;
      color: #333;
      font-size: 14px;
      line-height: 24px;
      &::before {
        content: ''; display: block;
        position: absolute; top: 3px; left: -115px;
        width: 43px; height: 43px;
        background: url('#{$image}/board/notice.png');
      }
    }
  }
}
.board-search {
  text-align: center;
  margin-bottom: 40px;
  padding: 19px 0;
    background: #e6edf3;
  .__wrap
  {
    display: inline-block;vertical-align: top;
    
    @include clearBoth('&');
    
  }
  .__condition,.__text,.__btn{float: left;}
  .__condition{
    width:109px;
    background-image: url('#{$image}/icon-select.png');
    background-repeat: no-repeat;
    background-position: right 10px center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .__text {width:calc(100% - 233px); margin-left: 5px;}
  .__btn {width: 113px; min-width: auto; margin-left: 5px; font-size: 16px;}
  legend {font-size: 0;}
}
.board-search-v2 {
  text-align: right;
  margin-bottom: 20px;
  .__wrap
  {
    padding: 8px;
    background: #e6edf3;
    display: inline-block;vertical-align: top;
    
    @include clearBoth('&');
    
  }
  .__condition,.__text,.__btn{float: left;}
  .__condition{
    width:109px;
    background-image: url('#{$image}/icon-select.png');
    background-repeat: no-repeat;
    background-position: right 10px center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .__text {width:calc(100% - 233px);}
  .__btn {width: 80px; min-width: auto; margin-left: 5px; font-size: 16px;}
  legend {font-size: 0;}
}

.board-search-v3 {
  position: absolute;right: 0;top: 12px;  
  .__wrap
  {
    display: inline-block;vertical-align: top;
    @include clearBoth('&');
  }
  .__condition,.__text,.__btn{float: left;}
  .__text {width:180px;font-size: 14px;height: 30px;letter-spacing: -0.025em;}
  .__btn {width: 60px; min-width: auto; margin-left: 5px; font-size: 16px;height: 30px;line-height: 30px;padding-left: 0;padding-right: 0;}
  legend {font-size: 0;}
}


.board-notice {
  position: relative;
  margin-bottom: 5px;
  padding-left: 23px;
  font-size: 12px;color: #999999;
  &::before {
    content: ''; display: block;
    position: absolute; top: 0; left: 0;
    width: 20px; height: 16px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: url('#{$image}/board/icon-msg.png');
  }
}


.board-total {
  position: relative;
  margin-bottom: 5px;
  padding-left: 15px;
  font-size:14px;color: #000000;
  &::before {
    content: ''; display: block;
    position: absolute; top: 0; left: 0;
    width: 12px; height: 16px;
    background-image: url('#{$image}/board/total.png');
  }
  .mb{
    position: absolute;
    right: 0;
    top: 0;
    font-size: 14px;
  }
}

.board-list-text {
  border-top: 2px solid #070002;
  caption {font-size: 0;color: transparent;}
  table {width: 100%;}
  th, td {
    height: 50px;
    border-bottom: 1px solid #dfdfdf;
    padding: 15px 10px;
    box-sizing: border-box;
    text-align: center;
  }
  a {
    display: inline-block;
    font-weight: 500;
    &:hover, &:focus{color:#0c76c0;}
  }
  .board-title {
    padding-left: 50px;
    text-align: left;
  }
}

.reply_item-1 {
  .board-title {
    a {
      position: relative;
      padding-left: 40px;
      &::before {
        content: ''; display: block;
        position: absolute; top: 0; left: 0;
        width: 16px; height: 18px;
        background-image: url('#{$image}/board/reple.png');
      }
    }
  }
}
.reply_item-2 {
  .board-title {
    a {
      position: relative;
      padding-left: 40px;
      &::before {
        content: ''; display: block;
        position: absolute; top: 0; left: 20px;
        width: 16px; height: 18px;
        background-image: url('#{$image}/board/reple.png');
      }
    }
  }
}

th.tal{
  text-align: left !important;
  padding-left: 20px;
}

tr.tac{
  td{
    text-align: center;
  }
}
td.tal{
  text-align: left !important;
}

//글쓰기 & 뷰
.board-table {
  border-top: 2px solid #2a2a2a;
  caption {font-size: 0;}
  table {width: 100%;table-layout: fixed;}
  th, td {
    border-bottom: 1px solid #dfdfdf;
    height: 50px;
    padding: 5px 15px;
    box-sizing: border-box;
  }
  th {
    white-space: nowrap;
    background: #e9edf6;
  }
  a{word-break: break-all;}
}

.board-view-cont {
  min-height: 350px;
  padding: 40px 5px;
}

td.no-data, div.no-data {
  padding-top: 60px;padding-bottom: 60px;
  text-align: center;
  strong {
    display: block;
    margin-top: 20px;
    font-weight: 300;
  }
}

.cs-list-nomal {
  @include listRest('&');
  li {
    margin-top: 7px;
    &:first-child {margin-top: 0;}
  }
}

.doc-warp {
  margin-top: 30px;
  border-top: 1px solid #000000;
  &> div {
    border-bottom: 1px solid #d9d9d9;
    &::after {display: block;content: '';clear: both;}
    &> * {float: left;}
  }
  p {
    width: 210px;
    text-align: center;
    line-height: 50px;
  }
  i {padding-left: 50px;}
  a {
    display: block;
    width: calc(100% - 211px);
    padding: 15px 0;
  }
  .__next {
    i::before {transform: rotate(180deg);}
  }
}

td.pl0{
  padding-left: 0;
}
td.pr0{
  padding-right: 0;
}


table.th-pl20{
  th{
    text-align: left;
    padding-left: 20px;
  }
}

table.td-pl30{
  td{
    padding-left: 30px;
  }
}

table.color-gray{
  td{
    color: #999 ;
  }
}

.board-vertical{
  border-top: 2px solid #000;
  .table{
    width: 100%;
  }
  th,
  td{
    border-bottom: 1px solid #d9d9d9;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  th{
    background-color: #e9edf6;text-align: center;
  }
  td{
    
  }
}

.board-horizontal{
  border-top: 2px solid #000;
  .table{
    width: 100%;
  }
  th,
  td{
    border-bottom: 1px solid #d9d9d9;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  th{
    background-color: #e9edf6;text-align: center;
  }
  td{
    padding-left: 10px;
    padding-right: 10px;

  }
}

.nodata{
  display: inline-block;
  background-image: url("#{$image}/icon-nodata.png");
  background-position: 0 0;background-repeat: no-repeat;
  color: #666666;
  padding-left: 45px;line-height: 30px;height: 30px;
}

.map-sort{
  overflow: hidden;
  a{
    display: block;
    float: left;
    width: 40px;height: 40px;
    background-repeat: no-repeat;
    background-position: 0 0;
    +a{
      margin-left: 5px;
    }
  }
  .type-1{background-image: url("#{$image}/icon-sort-map-1.png");}
  .type-2{background-image: url("#{$image}/icon-sort-map-2.png");}
  .type-3{background-image: url("#{$image}/icon-sort-map-3.png");}
  .type-4{background-image: url("#{$image}/icon-sort-map-4.png");}
}

.preview-map{
  height: 460px;
  background-color: #f8f8f8;
}
.preview-btn{font-size: 0;
  text-align: center;
  a{
    +a{
      margin-left: 10px;
    }
  }
}