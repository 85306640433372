@charset "utf-8";
@import './include/var';

.sub-tit {
    color: #1e5caf;
    font-weight: 400;
    margin-bottom: 20px;
    font-size: 18px;
}


.sub-tit-2 {
    color: #1e5caf;
    font-weight: 400;
    margin-bottom: 10px;
    font-size: 15px;
    position: relative;
    padding-left: 11px;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 8px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #2f71c9;
    }
}

.lh-txt {}

.tab-section {
    +.tab-section {
        margin-top: 50px;
    }
}

.table-list {
    border-top: 2px solid #5f8aea;
}

.table-list td.left {
    text-align: left;
    padding-left: 15px;
}

.table-list ul.square {
    margin-left: 20px;
    list-style-type: square;
}

.table-list caption.caption-bottom {
    caption-side: bottom;
}

.table-list {
    width: 100%;
    border-top: 2px solid #5f8aea;
}
table.dot td ul {
    text-align: left;
    margin-left: 20px;
    li{
        padding-left: 10px;
        position: relative; 
        &:after{content:"";width:   6px;height:     6px;background-color: #888;position: absolute;  left:   0;top:  5px;}
    }
}

.table-list th {
    padding: 5px;
    border-bottom: 1px solid #cdc9c4;
    border-left: 1px solid #cdc9c4;
    background: #f3f3f2;
    font-size: 14px;
    font-weight: 400;
}

.table-list th:first-child {
    border-left: none;
}

.table-list td {
    padding: 5px;
    text-align: center;
    font-size: 13px;
    border-bottom: 1px solid #cdc9c4;
    border-left: 1px solid #cdc9c4;
    line-height: 1.5;
}

.table-list td * {
    vertical-align: middle;
}

.table-list tr:hover td {
    background-color: #f6f6f6;
}

.table-list td:first-child {
    border-left: none;
}

.table-list td.alignLeft {
    text-align: left;
}

.table-list td span {
    display: inline-block;
    width: 25px;
    height: 25px;
    padding: 3px 0px 0 0px;
    color: #747886;
    font-size: 11px;
    letter-spacing: -1px;
    
}

.table-list {
    width: 100%;
}

.table-list {
    &.v2 {
        border-top: 2px solid #000;
    }

    th,
    td {
        padding: 15px 0;
    }

    thead {
        th {
            background-color: #f3f3f2;
        }
    }
}


.table-list-tiny{
  th, td{padding: 6px 0 !important;}
}

.dash-list {
    margin-left: 20px;
    li {
        line-height: 1.6;
        font-size: 14px;
        font-weight: 300;
        color: #333333;
        position: relative;
        padding-left: 12px;

        +li {
            margin-top: 6px;
        }

        &:after {
            content: "-";
            position: absolute;
            left: 0;
            top: 2px;
        }

        &.img {
            margin: 20px 0;

            &:after {
                display: none;
            }

            &-center {
                text-align: center;
            }

            .border {
                display: inline-block;
                padding: 20px;
                border: 1px solid #dddddd;
                border-radius: 5px;

                +.border {
                    margin-left: 25px;
                }

                strong {
                    text-align: center;
                    margin-top: 10px;
                    display: block;
                    font-weight: 500;
                    color: #1e5caf;
                    font-size: 17px;
                }
            }
        }
    }
}

.blit-list {
    li {
        line-height: 1.6;
        font-size: 14px;
        font-weight: normal;
        color: #333333;
        position: relative;
        padding-left: 12px;

        +li {
            margin-top: 10px;
        }

        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 9px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #2f71c9;
        }

        &.img {
            margin: 20px 0;

            &:after {
                display: none;
            }

            &-center {
                text-align: center;
            }

            .border {
                display: inline-block;
                padding: 20px;
                border: 1px solid #dddddd;
                border-radius: 5px;

                +.border {
                    margin-left: 25px;
                }

                strong {
                    text-align: center;
                    margin-top: 10px;
                    display: block;
                    font-weight: 500;
                    color: #1e5caf;
                    font-size: 17px;
                }
            }
        }
    }

    .note {
        color: #2f71c9;
        text-indent: -10px;
        padding-left: 10px;
        display: inline-block;
    }
}

.basic {
    &-tab {
        display: block;

        &-menu {
            overflow: hidden;
            margin: 35px 0 40px;
            border: 1px solid #ddd;

            a {
                display: block;
                float: left;
                width: 20%;
                background-color: #f8fafc;
                text-align: center;
                color: #333333;
                font-size: 16px;
                height: 48px;
                line-height: 48px;

                +a {
                    border-left: 1px solid #dddddd;
                }

                &.active {
                    background-color: #2f71c9;
                    color: #fff;
                }
            }

            &.-col4{
                a{width:25%;}
            }
        }

        &-content {
            display: none;

            .row {
                border: 1px solid #dddddd;
                overflow: hidden;

                .col {
                    float: left;
                    width: 33.333%;
                    text-align: center;
                    height: 178px;
                    padding-top: 18px;

                    +.col {
                        border-left: 1px solid #dddddd;
                    }

                    .tit {
                        text-align: center;
                        padding-bottom: 20px;

                        p {
                            height: 60px;
                        }

                        span {
                            color: #2f71c9;
                            font-size: 16px;
                        }
                    }

                    .txt {
                        color: #999999;
                        font-size: 12px;
                        display: inline-block;
                        text-align: left;
                    }
                }
            }
        }
    }
}


.mb5 {
    margin-bottom: 5px;
}

.mb15 {
    margin-bottom: 15px;
}

.mb50 {
    margin-bottom: 50px;
}


.source {
    text-align: right;
    font-size: 12px !important;
    line-height: 20px !important;
}

.system {
    p {
        font-size: 15px;
        line-height: 30px;
    }

    li {
        line-height: 30px;
    }

    ul {
        li {
            position: relative;
            padding-left: 10px;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 12px;
                width: 5px;
                height: 2px;
                background-color: #858585;
            }
        }
    }

    ol {
        padding-left: 16px;

        li {
            list-style: auto;
        }
    }

    &__box {
        padding: 15px;
        border: 1px solid #d9d9d9;

        &::after {
            display: block;
            content: '';
            clear: both;
        }

        &>* {
            width: calc((100% - 31px)/2);

            &:first-child {
                float: left;
                height: 310px;
                margin-right: 30px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            &:last-child {
                float: right;
            }

            p,
            li {
                font-size: 14px;
                line-height: 24px;
            }
        }

        a {
            display: block;
            position: relative;

            p {
                opacity: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                font-size: 30px;
                color: #fff;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                transition: 0.3s;
            }

            &:hover {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.2);
                }

                p {
                    opacity: 1;
                }
            }
        }
    }
}