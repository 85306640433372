@charset "utf-8";
@import './include/var';



/* =======================
  로그인
======================= */
.login {
  width: 380px;
  margin: 0 auto;
  .form-input {
    height: 50px;
    padding: 15px;
    border-color: #c1c8d1;
    margin-bottom: 10px;
    &::placeholder {color: #838383;}
  }
  &__tit {
    position: relative;
    margin-bottom: 55px;
    padding-top: 20px;
    font-size: 29px;
    font-family: 'KoPubDotumMedium';
    &::before, &::after {content: ''; position: absolute; left: 0;}
    &::before {
      top: 0;
      width: 100%; height: 1px;
      background-color: #e5e5e5;
    }
    &::after {
      top: -4px;
      width: 20px; height: 4px;
      background-color: #284d9e;
    }
  }
  &__txt {
    margin-bottom: 15px;
    line-height: 25px;
    font-weight: 500;
    font-family: 'KoPubDotumMedium';
    span {color: #2879ff;}
  }
  &__btn {
    margin-bottom: 13px;
    &::after {display: block;content: '';clear: both;}
    &> * {
      float: left;
      width: calc((100% - 5px) / 2);
      height: 60px; line-height: 60px;
      font-size: 14px;
    }
    .btn-login {margin-left: 4px;}
  }
  &__link {
    margin-bottom: 25px;
    border: 1px solid #c1c8d1;
    &::after {display: block;content: '';clear: both;}
    &> * {
      float: left;
      width: calc((100% - 3px) / 3);
      height: 46px; line-height: 46px;
      border-left: 1px solid #c1c8d1;
      font-size: 15px;
      text-align: center;
      &:first-child {border-left: none;}
    }
  }
  &__info {
    line-height: 25px;
    letter-spacing: -0.5px;
  }
}
