@charset "utf-8";
@import './include/var';


@import './include/login';
@import './include/board';
@import './include/lib';
@import './include/atlas';
@import './include/system';

.cont-image{text-align: center;border:1px solid #dddddd;border-radius:3px;padding:15px;}

.cont-description{margin-bottom: 10px;}